import { Col, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function ReturnPolicy() {
  return (
    <Layout>
      <SEO title="ReturnPolicy" />
      <Row className="container mx-auto text-wrap py-5">
        <Col>
          <h1>Return Policy</h1>
          <p>Last updated: June 1, 2023</p>
          <h4>SHIPPING RATES</h4>
          <p>
            SHIPPING RATES We proudly ship (Monday through Friday) through the
            Taiwan Post. Please use our Shipping rule during checkout for
            accurate shipping rates. Price* includes packaging, handling, and
            service fees, associated with shipping your goods.
          </p>
          <h4>SHIPPING POLICY</h4>
          <p>
            Tracking numbers are provided via order detail at the time of
            shipment.
          </p>
          <p>
            Any shipping charges incurred due to exchange for store credit are
            the responsibility of the customer.
          </p>
          <p>
            The customer assumes responsibility for any additional charges on
            rejected packages. This includes any orders returned due to
            non-claim, incorrect address, etc. Waltty Playing Card Studio is not
            responsible for customs fees incurred from imported goods. We
            appreciate your understanding.Also, do not have your package mailed
            to an address if the address is temporary. In some cases, packages
            may take several weeks to arrive. If you vacate the delivery address
            prior to receiving the package, we are not responsible for any fees
            to receive the package again or for a replacement to be mailed out.
          </p>
          <p>
            In rare cases, the amount paid for shipping may not be enough for
            the requested service. In those instances, we will do our best to
            let you know that a different shipping method may be required or
            additional fees needed in order to ship by the selected method. We
            reserve the right to change the shipping method for any reason.
          </p>
          <h4>LOST/STOLEN PACKAGES</h4>
          <p>
            Once orders have left our warehouse, Waltty Playing Card Studio
            cannot be held accountable for lost, stolen, or mis-delivered
            packages. If you’re having trouble tracking down a shipment, you’ll
            need to contact your local post office and/or parcel carrier for
            assistance. In the event of a replacement being mailed, Waltty
            Playing Card Studio reserves the right to mail the replacement via
            any shipping method available.
          </p>
        </Col>
        <h4>RETURNS & EXCHANGES</h4>
        <p>
          Waltty Playing Card Studio will gladly exchange or return any damaged
          items (still sealed in new condition) purchased on www.waltty.com
          within 30 days of the original order. Because many of our products are
          limited, we cannot offer refunds or credits on opened/used items.
        </p>
        <h4>RECEIVED A WRONG ITEM?</h4>
        <p>
          While we try our best to pack every order correctly, mistakes can
          happen. If you believe you have received an incorrect item, please
          contact us and provide us with an image of the item received and your
          order number. DO NOT open any items that you believe are incorrect. An
          opened item, even if incorrectly mailed/received, may be rejected for
          exchange or credit.
        </p>
        <h4>WHY IS MY ORDER DELAYED/NOT SHIPPED?</h4>
        <p>
          While we do our best to mail out all orders within 1 business day of
          that order being placed, there are various reasons. We apologize for
          this inconvenience and ask that you let us know as soon as possible of
          the damage. Any damage claims made on an order must be submitted via
          email to walttycontact@gmail.com within 7 days of receiving the
          package. Please include your order number and pictures of the damaged
          items and packaging.
        </p>
        <p>
          In the event the damage is caused by you or your postal service
          negligence, we reserve the right to refuse sending a replacement or
          issuing a refund. Any claims made after 7 days from receiving the
          product will not be considered for reimbursement or replacement. Also,
          do not discard the damaged item. You may be asked to return the
          damaged item prior to receiving a replacement or reimbursement.
        </p>
      </Row>
    </Layout>
  );
}

export default ReturnPolicy;
